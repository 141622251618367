import { StrictMode, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { Toaster } from 'react-hot-toast';
import { router } from '@/routes/routes';
import { Loading } from './components';
import { msalInstance } from './services/msal';

export const createApp = () => {
  return (
    <StrictMode>
      <Suspense fallback={<Loading show={true} />}>
        <MsalProvider instance={msalInstance}>
          <RouterProvider
            router={router}
            fallbackElement={<Loading show={true} />}
          />
          <Toaster position="top-right" gutter={8} />
        </MsalProvider>
      </Suspense>
    </StrictMode>
  );
};
