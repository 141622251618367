import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { Layout, RouteGuard } from '@/components';
import { homeIcon, integrationIcon } from '@/assets';
import { INavbarRoutes } from '@/types/components';
import { getImplementations } from '@/routes/Implementations/ImplementationsPage';
import {
  DEFAULT_IMPLEMENTATION,
  getImplementationsById,
} from '@/routes/Implementations/AddEditImplementationForm';

const Implementations = lazy(
  () => import('@/routes/Implementations/ImplementationsPage')
);
const AddEditImplementationForm = lazy(
  () => import('@/routes/Implementations/AddEditImplementationForm')
);

const routes: INavbarRoutes = [
  {
    name: 'Home',
    to: '/',
    icon: homeIcon,
  },
  {
    name: 'Implementations List',
    to: '/implementations',
    icon: integrationIcon,
  },
];

export const implementationsRoutes: RouteObject[] = [
  {
    element: <Layout navigationRoutes={routes} />,
    children: [
      {
        path: 'implementations',
        loader: async () => {
          return getImplementations();
        },
        element: (
          <RouteGuard>
            <Implementations />
          </RouteGuard>
        ),
      },
      {
        path: 'implementations/:id',
        loader: ({ params }) => {
          if (params.id) {
            return getImplementationsById(params.id);
          }
        },
        element: (
          <RouteGuard>
            <AddEditImplementationForm />
          </RouteGuard>
        ),
      },
      {
        path: 'implementations/add',
        loader: () => {
          return DEFAULT_IMPLEMENTATION;
        },
        element: (
          <RouteGuard>
            <AddEditImplementationForm />
          </RouteGuard>
        ),
      },
    ],
  },
];
