import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { IFormInputProps } from '@/types/components';

const DEFAULT_INPUT_STYLES = clsx([
  'peer',
  'border-b-2',
  'border-gray-300',
  'text-gray-900',
  'focus:outline-none',
  'placeholder-transparent',
  'w-full',
  'h-10',
]);

const DEFAULT_LABEL_STYLES = clsx([
  'absolute',
  'left-0',
  'cursor-pointer',
  '-top-3.5',
  'text-xs',
  'text-gray-500',
  'peer-placeholder-shown:text-sm',
  'peer-placeholder-shown:text-gray-400',
  'peer-placeholder-shown:top-2',
  'transition-all',
  'peer-focus:-top-3.5',
  'peer-focus:text-gray-600',
  'peer-focus:text-sm',
]);

const DISABLED_INPUT_STYLES = clsx('bg-gray-200', DEFAULT_INPUT_STYLES);

export const FormTextarea = forwardRef<
  HTMLTextAreaElement,
  Omit<IFormInputProps, 'type'>
>(
  (
    {
      labelText,
      id,
      onChange,
      onBlur,
      name,
      error,
      disabled,
      autoFocus,
      defaultValue,
    },
    ref
  ) => {
    return (
      <div className="relative">
        <textarea
          ref={ref}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          className={disabled ? DISABLED_INPUT_STYLES : DEFAULT_INPUT_STYLES}
          disabled={disabled}
          placeholder={labelText}
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
        ></textarea>
        <label htmlFor={id} className={DEFAULT_LABEL_STYLES}>
          {labelText}
        </label>
        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>
    );
  }
);

FormTextarea.displayName = 'FormTextarea';
