import { ReactNode } from 'react';
import { clsx } from 'clsx';

type Props = {
  children: ReactNode;
  className?: string;
};

export const Container = ({ children, className }: Props) => {
  return (
    <div data-cy="container" className={clsx('m-4 sm:m-8 lg:mx-12', className)}>
      {children}
    </div>
  );
};
