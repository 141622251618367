import { ReactNode } from 'react';
import { FormCheckbox } from './FormCheckbox';
import { FormInput } from './FormInput';
import { FormTextarea } from './FormTextarea';

type Props = {
  onSubmit: () => void;
  children: ReactNode;
};

export const BasicForm = ({ onSubmit, children }: Props) => {
  return (
    <form className="flex flex-col gap-4" onSubmit={onSubmit}>
      {children}
    </form>
  );
};

BasicForm.Input = FormInput;
BasicForm.Textarea = FormTextarea;
BasicForm.Checkbox = FormCheckbox;
