import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { logoutIcon } from '@/assets';
import { INavbarRoutes } from '@/types/components';
import { useUser } from '@/hooks';
import { Button } from '../Buttons';

type Props = {
  navigationRoutes: INavbarRoutes;
  isOpen: boolean;
};

export const Navbar = ({ navigationRoutes, isOpen }: Props) => {
  const { handleLogout, firstName, lastName, username } = useUser();
  return (
    <>
      <div
        className={clsx([
          'sm:sticky sm:top-0 sm:pt-2 sm:px-2 text-blue-900',
          'bg-blue-200 sm:min-h-screen',
          {
            'hidden sm:block': !isOpen,
          },
        ])}
      >
        <nav>
          <ul className={clsx('flex flex-col overflow-hidden space-y-2')}>
            <div className="mx-2">
              <div
                className={clsx([
                  'flex items-center justify-between py-2',
                  { 'gap-6': isOpen },
                ])}
              >
                <div>
                  <span
                    className={clsx([
                      'flex',
                      'w-14',
                      'h-14',
                      'justify-center',
                      'items-center',
                      'rounded-full',
                      'text-white',
                      'font-bold',
                      'text-center',
                      'bg-secondary',
                    ])}
                  >
                    {firstName.charAt(0) + lastName.charAt(0)}
                  </span>
                </div>

                <div
                  className={clsx([
                    'text-sm',
                    {
                      'sm:hidden': !isOpen,
                    },
                  ])}
                >
                  <p className="font-bold">
                    Welcome, {firstName + ' ' + lastName}
                  </p>
                  <p className="text-xs"> {username} </p>
                </div>
                <Button
                  className={clsx([
                    {
                      'sm:hidden': !isOpen,
                    },
                  ])}
                  color="red"
                  onClick={handleLogout}
                  tooltip="Logout"
                >
                  {logoutIcon}
                </Button>
              </div>
              {navigationRoutes.map((route, index) => (
                <Link key={index} to={route.to}>
                  <li
                    className={clsx([
                      'flex',
                      'justify-start',
                      'items-center',
                      'hover:bg-blue-300',
                      'py-2',
                      'border-b',
                      'border-blue-100',
                      {
                        'sm:justify-center': !isOpen,
                      },
                    ])}
                  >
                    <div
                      className={clsx([
                        'flex flex-wrap',
                        {
                          'gap-4': isOpen,
                        },
                      ])}
                    >
                      <i>{route.icon}</i>
                      <span
                        className={clsx(
                          { 'sm:hidden': !isOpen },
                          'font-bold ml-2'
                        )}
                      >
                        {route.name}
                      </span>
                    </div>
                  </li>
                </Link>
              ))}
            </div>
          </ul>
        </nav>
      </div>
    </>
  );
};
