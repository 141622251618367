import { useState } from 'react';

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCancelButton = () => {
    setIsOpen(false);
  };
  const handleOpenModalButton = () => {
    setIsOpen(true);
  };

  return {
    isOpen,
    handleOpenModalButton,
    handleCancelButton,
  };
};
