import { Link } from 'react-router-dom';
import { Button, Modal } from '@/components';

type Props = {
  continueTo: string;
  isOpen: boolean;
  handleCancelButton: () => void;
};

export const ConfirmationModal = ({
  continueTo,
  isOpen,
  handleCancelButton,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      handleCancel={() => handleCancelButton()}
      title="Are you sure?"
    >
      <div className="mt-2">
        <p>
          Your changes will not be persisted. Clicking{' '}
          <span className="font-bold"> &#34;Continue&#34; </span> will redirect
          you to the home page.
        </p>
      </div>
      <div className="flex justify-between sm:justify-end sm:gap-2 mt-4">
        <Button type="button" onClick={() => handleCancelButton()} color="blue">
          Cancel
        </Button>
        <Link to={continueTo}>
          <Button type="button" color="red">
            Continue
          </Button>
        </Link>
      </div>
    </Modal>
  );
};
