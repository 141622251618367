import { clsx } from 'clsx';
import { Form, useSubmit } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { BasicForm, Button } from '@/components';
import {
  NotificationEmail,
  NotificationTemplate,
} from '@/routes/FEDirect/loaders';
import { trashIcon } from '@/assets';

type Props = {
  data: NotificationEmail;
  storefrontId: string;
};

type NotificationEmailFormProps = {
  id: string;
  notificationTemplate: NotificationTemplate;
  fromEmail: string;
  fromName: string;
  toEmails: ReturnType<typeof convertEmailsToArray>;
  ccEmails: ReturnType<typeof convertEmailsToArray>;
  bccEmails: ReturnType<typeof convertEmailsToArray>;
};

const convertEmailsToArray = (emails?: string) => {
  if (emails == null) {
    return [
      {
        value: '',
      },
    ];
  }
  return emails
    .replace(' ', '')
    .split(',')
    .map((el) => {
      return { value: el };
    });
};

const convertEmailsToString = (
  emails: ReturnType<typeof convertEmailsToArray>
) => {
  return emails
    .map((x) => x['value'])
    .filter((x) => x != '')
    .join(', ');
};

export const NotificationEmailForm = ({ data, storefrontId }: Props) => {
  const submit = useSubmit();
  const methods = useForm<NotificationEmailFormProps>({
    defaultValues: {
      id: data.id,
      notificationTemplate: data.notificationTemplate,
      fromEmail: data.fromEmail,
      fromName: data.fromName,
      toEmails: convertEmailsToArray(data.toEmails),
      ccEmails: convertEmailsToArray(data.ccEmails),
      bccEmails: convertEmailsToArray(data.bccEmails),
    },
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const {
    fields: toEmails,
    append: appendToEmails,
    remove: removeToEmails,
  } = useFieldArray({
    control,
    name: 'toEmails',
  });

  const {
    fields: ccEmails,
    append: appendCcEmails,
    remove: removeCcEmails,
  } = useFieldArray({
    control,
    name: 'ccEmails',
  });

  const {
    fields: bccEmails,
    append: appendBccEmails,
    remove: removeBccEmails,
  } = useFieldArray({
    control,
    name: 'bccEmails',
  });

  const gridStyles = clsx([
    'grid',
    'grid-cols-1',
    'sm:grid-cols-2',
    'md:grid-cols-3',
    'lg:grid-cols-4',
    'gap-4',
  ]);

  const onSubmit = (data: NotificationEmailFormProps) => {
    const payload: NotificationEmail & { storefrontId: string } = {
      id: data.id,
      storefrontId: storefrontId,
      notificationTemplate: data.notificationTemplate,
      fromName: data.fromName,
      fromEmail: data.fromEmail,
      toEmails: convertEmailsToString(data.toEmails),
      ccEmails: convertEmailsToString(data.ccEmails),
      bccEmails: convertEmailsToString(data.bccEmails),
    };
    const formData = new FormData();
    formData.append('payload', JSON.stringify(payload));
    submit(formData, {
      method: 'post',
    });
  };
  return (
    <Form
      method="put"
      className={clsx(['flex', 'flex-col', 'gap-4', 'pb-2', 'pt-4'])}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset>
        <legend className={clsx(['pb-4'])}>Sender Information</legend>
        <div
          className={clsx(['grid', 'grid-cols-1', 'sm:grid-cols-2', 'gap-4'])}
        >
          <BasicForm.Input
            {...register('fromName', { required: '"From Name" is required.' })}
            labelText="From Name"
            id="fromName"
            defaultValue={data.fromName}
            error={errors.fromName?.message}
          />

          <BasicForm.Input
            {...register('fromEmail', {
              required: '"From Email" is required.',
            })}
            labelText="From Email"
            id="fromEmail"
            defaultValue={data.fromEmail}
            type="email"
            error={errors.fromEmail?.message}
          />
        </div>
      </fieldset>
      <fieldset>
        <legend className={clsx(['pb-2'])}>Recipient Information</legend>
        <h3 className={clsx(['font-bold', 'pb-4'])}>To:</h3>
        <div className={gridStyles}>
          {toEmails.map((el, index) => {
            return (
              <div key={el.id} className={clsx(['flex', 'gap-2'])}>
                <BasicForm.Input
                  {...register(`toEmails.${index}.value`, {
                    required: '"To Emails" is required.',
                  })}
                  labelText={`To Email ${index + 1}`}
                  id="toEmails"
                  type="email"
                  error={errors.toEmails?.[index]?.value?.message}
                  defaultValue={toEmails[index].value}
                />
                <Button
                  className={clsx(['w-12'])}
                  color="red"
                  type="button"
                  onClick={() => removeToEmails(index)}
                  disabled={toEmails.length <= 1}
                  tooltip="Delete Email"
                >
                  {trashIcon}
                </Button>
                {index == toEmails.length - 1 && (
                  <Button
                    className={clsx(['w-12'])}
                    type="button"
                    onClick={() => appendToEmails({ value: '' })}
                    tooltip="Add New Email"
                  >
                    +
                  </Button>
                )}
              </div>
            );
          })}
        </div>
        <h3 className={clsx(['font-bold', 'pb-4'])}>CC:</h3>
        <div className={gridStyles}>
          {ccEmails.map((el, index) => {
            return (
              <div key={el.id} className={clsx(['flex', 'gap-2'])}>
                <BasicForm.Input
                  {...register(`ccEmails.${index}.value`)}
                  labelText={`CC Email ${index + 1}`}
                  id="ccEmails"
                  defaultValue={ccEmails[index].value}
                  type="email"
                />
                <Button
                  className={clsx(['w-12'])}
                  color="red"
                  type="button"
                  onClick={() => removeCcEmails(index)}
                  disabled={ccEmails.length <= 1}
                  tooltip="Delete Email"
                >
                  {trashIcon}
                </Button>
                {index == ccEmails.length - 1 && (
                  <Button
                    className={clsx(['w-12'])}
                    type="button"
                    onClick={() => appendCcEmails({ value: '' })}
                    tooltip="Add New Email"
                  >
                    +
                  </Button>
                )}
              </div>
            );
          })}
        </div>
        <h3 className={clsx(['font-bold', 'pb-4'])}>BCC:</h3>

        <div className={gridStyles}>
          {bccEmails.map((el, index) => {
            return (
              <div key={el.id} className={clsx(['flex', 'gap-2'])}>
                <BasicForm.Input
                  {...register(`bccEmails.${index}.value`)}
                  labelText={`BCC Email ${index + 1}`}
                  id="bccEmails"
                  defaultValue={el.value}
                  type="email"
                />
                <Button
                  className={clsx(['w-12'])}
                  color="red"
                  type="button"
                  onClick={() => removeBccEmails(index)}
                  disabled={bccEmails.length <= 1}
                  tooltip="Delete Email"
                >
                  {trashIcon}
                </Button>
                {index == bccEmails.length - 1 && (
                  <Button
                    className={clsx(['w-12'])}
                    type="button"
                    onClick={() => appendBccEmails({ value: '' })}
                    tooltip="Add New Email"
                  >
                    +
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      </fieldset>
      <Button type="submit"> Save </Button>
    </Form>
  );
};
