import {
  Configuration,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser';
import { AxiosRequestConfig } from 'axios';
const AAD_EXPOSED_SCOPE = process.env.REACT_APP_EXPOSED_AAD_SCOPE;
const scopes = [AAD_EXPOSED_SCOPE ? AAD_EXPOSED_SCOPE : ''];
const loginRequest: RedirectRequest = {
  scopes,
};

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
    authority: process.env.REACT_APP_MSAL_AUTHORITY || '', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI || '',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPPI) => {
        if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
          switch (level) {
            case LogLevel.Error:
              console.error({ message, containsPPI });
              return;
            case LogLevel.Info:
              console.info({ message, containsPPI });
              return;
            case LogLevel.Verbose:
              console.debug({ message, containsPPI });
              return;
            case LogLevel.Warning:
              console.warn({ message, containsPPI });
              return;
          }
        }
      },
      logLevel: LogLevel.Error,
    },
  },
};

const refreshToken = async (config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers['Content-Type'] = 'application/json';
  }

  const account = msalInstance.getAllAccounts()[0];
  if (account) {
    await msalInstance
      .acquireTokenSilent({
        scopes,
        account,
      })
      .then((accessTokenResponse) => {
        if (accessTokenResponse) {
          const accessToken = accessTokenResponse.accessToken;

          if (config.headers && accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
          }
        }
      })
      .catch(async (error) => {
        const account = msalInstance.getAllAccounts()[0];
        await msalInstance.acquireTokenRedirect({
          scopes,
          account,
        });
        throw new Error(error);
      });
  }
  return config;
};

const msalInstance = new PublicClientApplication(msalConfig);
export { msalInstance, loginRequest, scopes, refreshToken };
