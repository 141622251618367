import { lazy } from 'react';
import { LoaderFunctionArgs, RouteObject } from 'react-router-dom';
import { INavbarRoutes } from '@/types/components';
import { Layout } from '@/components';
import { homeIcon, storeIcon } from '@/assets';
import { loader as getAllStorefronts } from '@/routes/FEDirect/StorefrontsPage';
import {
  loader as getPersonaForm,
  action as updatePersonaRoles,
} from '@/routes/FEDirect/AddEditPersonaRoleForm';

import { routeIds } from './constants';
import { getStorefrontById } from './loaders';
import { createUpdateStorefront, updateNotificationEmail } from './actions';

const PersonasPage = lazy(() => import('@/routes/FEDirect/PersonasPage'));
const StorefrontsPage = lazy(() => import('@/routes/FEDirect/StorefrontsPage'));
const AddEditStorefrontPage = lazy(
  () => import('@/routes/FEDirect/AddEditStorefrontPage')
);
const AddEditPersonaRoleForm = lazy(
  () => import('@/routes/FEDirect/AddEditPersonaRoleForm')
);
const ErrorPage = lazy(() => import('@/routes/ErrorPage'));
const NotificationTemplatesPage = lazy(
  () => import('@/routes/FEDirect/NotificationTemplatesPage')
);

const navigationRoutes: INavbarRoutes = [
  {
    name: 'Home',
    to: '/',
    icon: homeIcon,
  },
  {
    name: 'Storefronts',
    to: '/fe-direct/storefronts/',
    icon: storeIcon,
  },
];

export const feDirectRoutes: RouteObject[] = [
  {
    path: '/fe-direct/',
    element: <Layout navigationRoutes={navigationRoutes} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'storefronts',
        children: [
          {
            index: true,
            loader: getAllStorefronts,
            element: <StorefrontsPage />,
          },
          {
            path: 'add',
            action: createUpdateStorefront,
            element: <AddEditStorefrontPage />,
          },
          {
            path: ':storefrontId',
            loader: getStorefrontById,
            id: routeIds.STOREFRONT_BY_ID,
            children: [
              {
                index: true,
                action: createUpdateStorefront,
                element: <AddEditStorefrontPage />,
              },
              {
                path: 'email-notifications',
                action: updateNotificationEmail,
                element: <NotificationTemplatesPage />,
              },
              {
                path: 'personas',
                children: [
                  {
                    index: true,
                    element: <PersonasPage />,
                  },
                  {
                    path: ':personaId',
                    loader: getPersonaForm,
                    action: updatePersonaRoles,
                    element: <AddEditPersonaRoleForm />,
                  },
                  {
                    path: 'new',
                    loader: getPersonaForm,
                    action: updatePersonaRoles,
                    element: <AddEditPersonaRoleForm />,
                  },
                  {
                    path: 'new/:personaId',
                    loader: async (args: LoaderFunctionArgs) => {
                      const rtn = await getPersonaForm(args);
                      if (rtn) rtn.personaId = null;
                      return rtn;
                    },
                    action: updatePersonaRoles,
                    element: <AddEditPersonaRoleForm />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
