import { ReactNode } from 'react';
import { clsx } from 'clsx';

type Props = {
  text?: string;
  children: ReactNode;
};

export const Tooltip = ({ text, children }: Props) => {
  const disabled = text == null || text == '';
  if (disabled) {
    return <>{children}</>;
  }
  return (
    <>
      <div className={clsx('group', 'relative')} aria-label={text}>
        <>{children}</>
        {text && (
          <span
            className={clsx([
              'absolute',
              'bottom-5',
              'right-10',
              'scale-0',
              'rounded',
              'bg-gray-800',
              'p-2',
              'text-xs',
              'text-white',
              'group-hover:scale-100',
              'delay-500',
              'min-w-max'
            ])}
          >
            {text}
          </span>
        )}
      </div>
    </>
  );
};
