import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Container, HeaderBar, Navbar } from '@/components';
import { INavbarRoutes } from '@/types/components';

type Props = {
  navigationRoutes: INavbarRoutes;
};

export const Layout = ({ navigationRoutes }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <HeaderBar setIsOpen={setIsOpen} />
      <div className="sm:flex">
        <Navbar isOpen={isOpen} navigationRoutes={navigationRoutes} />
        <Container className="sm:w-full">
          <Outlet />
        </Container>
      </div>
    </>
  );
};
