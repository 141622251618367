
import { toast } from 'react-hot-toast';
import { ActionFunctionArgs, redirect } from 'react-router-dom';
import axios from '@/services/axios';
import { FormPayload } from '@/types/types';
import { NotificationEmail, NotificationTemplate } from './loaders';

export type UpdateNotificationEmailFormData = {
  id: string;
  storefrontId: string;
  notificationTemplate: NotificationTemplate;
  toEmails: Record<number, string>;
  fromEmail: string;
  ccEmails: Record<number, string>;
  bccEmails: Record<number, string>;
  fromName: string;
};


export const updateNotificationEmail = async ({ request }: ActionFunctionArgs) => {
  const toastId = toast.loading('Loading...');
  try {
    const formData = Object.fromEntries(await request.formData()) as FormPayload;
    const { toEmails,
      fromEmail,
      fromName,
      ccEmails,
      bccEmails,
      id,
      notificationTemplate,
      storefrontId
    } = JSON.parse(formData.payload) as NotificationEmail & { storefrontId: string };
    const payloadMapping = {
      id: id,
      templateId: notificationTemplate.id,
      storefrontId,
      toEmails: toEmails,
      fromEmail,
      fromName,
      ccEmails: ccEmails?.length == 0 ? null : ccEmails,
      bccEmails: bccEmails?.length == 0 ? null : bccEmails
    }
    const resp = await axios.request<NotificationEmail & { storefrontId: string }>({
      method: 'put',
      data: payloadMapping,
      url: 'fedirect-mgmt/Storefront/Email-Notification',
    });

    toast.dismiss(toastId);
    if (resp.status == 200) {
      toast.success(
        `Successfully updated ${resp.data.notificationTemplate.name}.`
      );
    }
    return redirect('/fe-direct/storefronts');
  } catch (error) {
    toast.dismiss(toastId);
    toast.error("Unable to update email notification")
    console.error(error);
  }
};


export const createUpdateStorefront = async ({ request }: ActionFunctionArgs) => {
  type AddEditStorefrontResponse = {
    id: string;
    displayName: string;
  };
  const toastId = toast.loading('Loading...');
  try {
    const formData = Object.fromEntries(
      await request.formData()
    ) as FormPayload;
    const payload = JSON.parse(formData.payload);
    const resp = await axios.request<AddEditStorefrontResponse>({
      method: payload.id ? 'put' : 'post',
      data: payload,
      url: 'fedirect-mgmt/Storefront',
    });

    toast.dismiss(toastId);
    if (resp.status == 200) {
      toast.success(
        `Successfully updated ${resp.data.displayName} in storefronts.`
      );
    }
    if (resp.status == 201) {
      toast.success(
        `Successfully added ${resp.data.displayName} to storefronts.`
      );
    }
    return redirect('/fe-direct/storefronts');
  } catch (error) {
    toast.dismiss(toastId);
    console.error(error);
  }
};
