import { AxiosResponse } from 'axios';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useState } from 'react';
import { useModal } from '@/hooks';
import {
  Breadcrumbs,
  Button,
  Loading,
  ConfirmationModal,
  BasicForm,
} from '@/components';
import { Implementation } from '@/types/loaders';
import DEFAULT_FORM_VERSIONS from '@/constants/form-versions.json';
import { getErrorMessage } from '@/helpers';
import axios from '@/services/axios';

const AddEditImplementationForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const implementation = useLoaderData() as Implementation;
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Implementation>({
    defaultValues: implementation,
  });
  const {
    fields: childCompaniesFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'childCompanies',
  });

  const watchChildCompaniesArray = watch('childCompanies');
  const implementationName = watch('name');
  const controlledChildCompaniesFields = childCompaniesFields.map(
    (field, index) => {
      return {
        ...field,
        ...watchChildCompaniesArray[index],
      };
    }
  );

  const onSubmit = async (data: Implementation) => {
    try {
      setIsLoading(true);
      const payload = {
        ...data,
        formVersions: data.formVersions.filter((form) => form.version !== ''),
      };
      if (params.id) {
        const resp = await editImplementation(payload);
        if (resp?.status == 200) {
          toast.success(`${implementation.name} has been updated`);
          navigate('/implementations');
        }
      } else {
        const resp = await addImplementation(payload);
        if (resp?.status == 200) {
          toast.success('Implementation Added');
          navigate('/implementations');
        }
      }
      setIsLoading(false);
    } catch (error) {
      toast.error('Unable to submit form.');
      navigate('/');
    }
  };

  const { isOpen, handleOpenModalButton, handleCancelButton } = useModal();

  const navigate = useNavigate();

  const params = useParams();
  const pageType = params.id ? 'Edit' : 'Add';

  return (
    <>
      <Loading show={isLoading} />
      <Breadcrumbs
        path={[
          { title: 'Implementations', to: '/implementations' },
          { title: pageType, to: '' },
          { title: implementationName, to: '' },
        ]}
      />
      <BasicForm onSubmit={handleSubmit(onSubmit)}>
        <BasicForm.Input
          autoFocus
          labelText="Name"
          id="name"
          error={errors.name?.message}
          {...register('name', { required: '"Name" field is required.' })}
        />

        <BasicForm.Input
          labelText="Company Number"
          id="number"
          disabled={pageType == 'Edit'}
          error={errors.number?.message}
          {...register('number', {
            required: '"Company Number" is required.',
          })}
        />

        <div className="flex justify-between items-center">
          <h3> Child Companies </h3>
          <Button
            type="button"
            onClick={() => append({ number: '' })}
            color="blue"
          >
            + Add
          </Button>
        </div>
        {controlledChildCompaniesFields.length <= 0 ? (
          <p className="text-gray-500"> No child companies defined. </p>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
              {controlledChildCompaniesFields?.map((company, index) => {
                return (
                  <div className="relative" key={company.id}>
                    <div className="flex justify-between">
                      <BasicForm.Input
                        autoFocus={index > 0}
                        id={`childCompanies.${index}.number`}
                        defaultValue={company.number}
                        labelText={`Child Company ${index + 1}`}
                        {...register(`childCompanies.${index}.number`)}
                      />
                      <Button
                        type="button"
                        onClick={() => remove(index)}
                        color="red"
                      >
                        x
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <h3> Program Versions </h3>
        {implementation.formVersions &&
          implementation.formVersions.map((pv, index) => (
            <BasicForm.Input
              key={index}
              labelText={pv.actionTypeDisplayName}
              id={`formVersions.${index}.version`}
              {...register(`formVersions.${index}.version`, {
                maxLength: 32,
              })}
            />
          ))}
        <div className="flex justify-end gap-4">
          <Button type="button" onClick={handleOpenModalButton} color="red">
            Cancel
          </Button>
          <Button color="blue">Submit</Button>
        </div>
      </BasicForm>
      <ConfirmationModal
        handleCancelButton={handleCancelButton}
        isOpen={isOpen}
        continueTo="/implementations"
      />
    </>
  );
};

const addImplementation = async (payload: Partial<Implementation>) => {
  try {
    const resp = await axios.post<AxiosResponse<string>>(
      '/implementationapi/implementation/add',
      payload
    );
    return resp;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
};

const editImplementation = async (payload: Partial<Implementation>) => {
  try {
    const resp = await axios.post<AxiosResponse<string>>(
      '/implementationapi/implementation/edit',
      payload
    );
    return resp;
  } catch (err) {
    reportError({ message: getErrorMessage(err) });
  }
};

export const getImplementationsById = async (id: string) => {
  const toastId = toast.loading('Loading...');
  try {
    const resp = await axios.get<Implementation>(
      '/implementationapi/implementation/getImplementation/',
      {
        params: {
          id,
        },
      }
    );
    if (resp?.status == 200) {
      const data = resp.data;
      data.formVersions = [
        ...data.formVersions,
        ...DEFAULT_FORM_VERSIONS,
      ].filter((value, index, self) => {
        return (
          index ==
          self.findIndex((t) => {
            return t.actionTypeId == value.actionTypeId;
          })
        );
      });
      toast.dismiss(toastId);
      return data;
    }
  } catch (err) {
    toast.dismiss(toastId);
    reportError({ message: getErrorMessage(err) });
  }
};

export const DEFAULT_IMPLEMENTATION: Partial<Implementation> = {
  name: '',
  number: '',
  childCompanies: [],
  formVersions: DEFAULT_FORM_VERSIONS,
};

export default AddEditImplementationForm;
