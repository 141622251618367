import { forwardRef } from 'react';
import { clsx } from 'clsx';
import { IFormInputProps } from '@/types/components';

const DEFAULT_INPUT_STYLES = clsx(['mr-2']);
const DEFAULT_LABEL_STYLES = clsx(['font-medium']);
const DISABLED_INPUT_STYLES = clsx('bg-gray-200', DEFAULT_INPUT_STYLES);

export const FormCheckbox = forwardRef<
  HTMLInputElement,
  Omit<IFormInputProps, 'error'>
>(
  (
    {
      labelText,
      id,
      onChange,
      onBlur,
      name,
      disabled,
      autoFocus = false,
      type = 'checkbox',
      value,
    },
    ref
  ) => {
    return (
      <>
        <div className="flex items-center">
          <input
            autoFocus={autoFocus}
            // defaultValue={defaultValue}
            className={disabled ? DISABLED_INPUT_STYLES : DEFAULT_INPUT_STYLES}
            disabled={disabled}
            type={type}
            placeholder={labelText}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            name={name}
            value={value}
          />
          <label htmlFor={id} className={DEFAULT_LABEL_STYLES}>
            {labelText}
          </label>
        </div>
      </>
    );
  }
);

FormCheckbox.displayName = 'FormCheckbox';
