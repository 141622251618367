import { useState, useEffect } from 'react';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { scopes } from '@/services/msal';

export const useUser = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('')

  useEffect(() => {
    if (account && account.name) {
      setFirstName(account.name.split(' ')[0]);
      setLastName(account.name.split(' ')[1]);
      if (account.username) {
        setUsername(account.username)
      }
    }

    return () => {
      setFirstName('')
      setLastName('')
    }
  }, [account, username]);

  const handleLogin = async () => {
    await instance.loginRedirect({
      scopes,
    });
  };

  const handleLogout = async () => {
    await instance.logoutRedirect({
      postLogoutRedirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    });
  };

  return {
    firstName,
    lastName,
    username,
    handleLogin,
    handleLogout,
    isAuthenticated: useIsAuthenticated(),
  };
};
