import { ReactNode } from 'react';

type Props = {
  text?: string;
  children?: ReactNode;
};

export const PanelHeader = ({ text, children }: Props) => {
  return (
    <h3 className="self-center font-semibold py-2">{text ? text : children}</h3>
  );
};
