import { toast } from 'react-hot-toast';
import { LoaderFunctionArgs } from 'react-router-dom';
import { getErrorMessage } from '@/helpers';
import axios from '@/services/axios';

export type StorefrontByIdResponse = {
  id: string;
  displayName: string;
  url: string;
  number: string;
  personas: {
    id: string;
    sortOrder: number;
    displayName: string;
    name: string;
    roles: {
      id: string;
      displayName: string;
      name: string;
      sortOrder: number;
    }[];
  }[];
  notificationEmails: NotificationEmail[];
  settingValues: {
    setting: {
      name: string
    },
    value: string
  }[]

};


export type NotificationTemplate = {
  id: string;
  name: string;
  isSuccess: boolean;
}

export type NotificationEmail = {
  id: string;
  notificationTemplate: NotificationTemplate;
  toEmails: string;
  fromEmail?: string;
  fromName?: string;
  ccEmails?: string;
  bccEmails?: string;
}

export const getStorefrontById = async ({ params }: LoaderFunctionArgs) => {
  const toastId = toast.loading('Loading...');
  try {
    const storefrontId = params.storefrontId;
    const resp = await axios.get<StorefrontByIdResponse>(
      `/fedirect-mgmt/storefront/${storefrontId}`
    );
    toast.dismiss(toastId);
    if (resp.data) {
      return resp.data;
    }
  } catch (err) {
    console.error('FEDirect:::Loaders:::getStorefrontById', { err });
    reportError({ message: getErrorMessage(err) });
    toast.dismiss(toastId);
  }
};
