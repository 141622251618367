import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';
import { useUser } from '@/hooks';

type Props = {
  children: JSX.Element;
};
export const RouteGuard = ({ children }: Props): JSX.Element => {
  const { isAuthenticated } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      toast.error(
        'Unable to access this resource. Please sign in with a valid account.'
      );
      navigate('/');
    }
  });
  return children;
};
