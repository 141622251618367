import { useForm } from 'react-hook-form';
import {
  ActionFunctionArgs,
  Form,
  LoaderFunctionArgs,
  redirect,
  useLoaderData,
  useSubmit,
} from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {
  Breadcrumbs,
  Button,
  ConfirmationModal,
  BasicForm,
  RouteGuard,
} from '@/components';
import axios from '@/services/axios';
import { useModal } from '@/hooks';
import { getErrorMessage } from '@/helpers';
import { FormPayload } from '@/types/types';

type AvailableRolesResponse = {
  storefrontId: string;
  personaId: string | null;
  storefrontName: string;
  personaName: string;
  personaRoles: Array<{
    id: string;
    name: string;
    isActive: boolean;
  }>;
};

type FormResponsePayload = {
  newPersonaName: string;
  storefrontId: string;
};

export const AddEditPersonaRoleForm = () => {
  const { storefrontName, personaName, personaRoles, storefrontId, personaId } =
    useLoaderData() as AvailableRolesResponse;
  const { handleOpenModalButton, isOpen, handleCancelButton } = useModal();
  const submit = useSubmit();
  const onSubmit = async (data: AvailableRolesResponse) => {
    const formData = new FormData();
    formData.append('payload', JSON.stringify(data));
    submit(formData, {
      method: 'post',
    });
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AvailableRolesResponse>({
    defaultValues: {
      storefrontId,
      personaName,
      personaRoles,
      personaId,
    },
  });

  return (
    <RouteGuard>
      <>
        <Breadcrumbs
          path={[
            {
              title: 'Storefronts',
              to: '/fe-direct/storefronts',
            },
            {
              title: storefrontName,
              to: '/fe-direct/storefronts/' + storefrontId + '/personas',
            },
            {
              title: 'Personas',
              to:
                '/fe-direct/storefronts/' + storefrontId + '/personas#personas',
            },
            {
              title: watch('personaName') ?? 'New',
              to: '#',
            },
          ]}
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BasicForm.Input
            autoFocus
            labelText="Name"
            id="personaName"
            error={errors.personaName?.message}
            {...register('personaName', {
              required: '"Name" field is required.',
            })}
          />
          <h3 className="font-bold">Select Roles</h3>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-2">
            {personaRoles.map((el, index) => (
              <BasicForm.Checkbox
                key={index}
                {...register(`personaRoles.${index}.isActive`)}
                labelText={el.name}
                id={el.id}
              />
            ))}
          </div>
          <div className="flex justify-end gap-4">
            <Button type="button" onClick={handleOpenModalButton} color="red">
              Cancel
            </Button>
            <Button color="blue">Submit</Button>
          </div>
        </Form>
        <ConfirmationModal
          handleCancelButton={handleCancelButton}
          isOpen={isOpen}
          continueTo={`/fe-direct/storefronts/${storefrontId}/personas`}
        />
      </>
    </RouteGuard>
  );
};

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const toastId = toast.loading('Loading...');
  try {
    const personaId = params?.personaId;
    const storefrontId = params?.storefrontId;
    const resp = await axios.get<AvailableRolesResponse>(
      '/fedirect-mgmt/availableRoles',
      {
        params: {
          personaId,
          storefrontId,
        },
      }
    );
    toast.dismiss(toastId);
    return resp.data;
  } catch (err) {
    toast.dismiss(toastId);
    reportError({ message: getErrorMessage(err) });
  }
};

export const action = async ({ request, params }: ActionFunctionArgs) => {
  const toastId = toast.loading('Loading...');
  const data = Object.fromEntries(await request.formData()) as FormPayload;
  try {
    const payload = JSON.parse(data.payload) as AvailableRolesResponse;
    payload.personaRoles = payload.personaRoles.filter((el) => el.isActive);
    const resp = await axios.put<FormResponsePayload>(
      'fedirect-mgmt/Storefront/Persona',
      payload
    );
    toast.dismiss(toastId);
    if (resp.status == 200) {
      toast.success(
        `Successfully updated ${resp.data.newPersonaName} in ${resp.data.storefrontId} storefront.`
      );
    }
    return redirect(`/fe-direct/storefronts/${params.storefrontId}/personas`);
  } catch (err) {
    toast.dismiss(toastId);
    toast.error('Unable to update personas list.');
    reportError({ message: getErrorMessage(err) });
  }
};

export default AddEditPersonaRoleForm;
