import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { implementationsRoutes } from '@/routes/Implementations';
import { feDirectRoutes } from './FEDirect/routes';
const LandingPage = lazy(() => import('@/routes/LandingPage'));
const ErrorPage = lazy(() => import('@/routes/ErrorPage'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
  ...implementationsRoutes,
  ...feDirectRoutes,
]);
