import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { PanelHeader } from './PanelHeader';
import { PanelLink } from './PanelLink';

type Props = {
  children: ReactNode;
  classes?: string;
};

export const FlatPanel = ({ classes, children }: Props) => {
  return (
    <>
      <div
        data-cy="flatPanel"
        className={clsx([
          classes,
          'border-b',
          'py-2',
          'sm:text-gray-400',
          'sm:hover:text-gray-800',
          'sm:flex',
          'sm:flex-wrap',
          'sm:justify-between',
          'hover:bg-slate-100',
          'rounded',
        ])}
      >
        {children}
      </div>
    </>
  );
};

FlatPanel.Header = PanelHeader;
FlatPanel.Link = PanelLink;
