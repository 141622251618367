export const delay = async (ms: number): Promise<void> => {
  return await new Promise((resolve) => setTimeout(resolve, ms));
};

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export const isNavigable = (
  isAuthenticated: boolean,
  protectedRoute?: boolean
) => {
  if (!isAuthenticated && protectedRoute) {
    return false;
  }
  return true;
};
