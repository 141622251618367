import { toast } from 'react-hot-toast';
import { Link, useLoaderData } from 'react-router-dom';
import { Button, FlatPanel } from '@/components';
import { gearIcon } from '@/assets';
import { Implementation } from '@/types/loaders';
import { getErrorMessage } from '@/helpers';
import axios from '@/services/axios';
const ImplementationsPage = () => {
  const implementationsList = useLoaderData() as Implementation[];
  return (
    <>
      {implementationsList ? (
        <>
          <div className="pt-2 flex flex-wrap justify-between gap-8 items-center mb-2">
            <h1 className="text-lg font-bold text-gray-500 hover:text-gray-800">
              Implementations ({implementationsList.length})
            </h1>
            <Link to="/implementations/add">
              <Button color="special">+ Add</Button>
            </Link>
          </div>
          {implementationsList.map(({ id, name }, index) => (
            <FlatPanel key={index}>
              <FlatPanel.Header> {name} </FlatPanel.Header>
              <FlatPanel.Link to={`./${id}`}>
                <span>{gearIcon}</span>
                <span>Edit</span>
              </FlatPanel.Link>
            </FlatPanel>
          ))}
        </>
      ) : (
        <>
          <span className="font-bold text-gray-600">
            No implementation data available.
          </span>
        </>
      )}
    </>
  );
};

export const getImplementations = async () => {
  const toastId = toast.loading('Loading...');
  try {
    const resp = await axios.get<Implementation[]>(
      '/implementationapi/implementation/getImplementations'
    );
    toast.dismiss(toastId);
    if (resp?.status == 200) {
      return resp.data;
    }
  } catch (err) {
    toast.dismiss(toastId);
    reportError({ message: getErrorMessage(err) });
  }
};

export default ImplementationsPage;
