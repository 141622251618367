import axios from 'axios';
import { toast } from 'react-hot-toast';
import { refreshToken } from './msal';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
});

instance.interceptors.request.use(refreshToken, (error) => {
  Promise.reject(error);
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      toast.error('Unauthorized.');
    }
    if (error?.response?.status === 403) {
      toast.error(
        "Forbidden. You don't have permissions to access this resource."
      );
    }
    return Promise.reject({ ...error });
  }
);

export default instance;
