import { Link, useLoaderData } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { clsx } from 'clsx';
import { envelopeIcon, gearIcon, userIcon } from '@/assets';
import { Breadcrumbs, Button, FlatPanel, RouteGuard } from '@/components';
import { getErrorMessage } from '@/helpers';
import axios from '@/services/axios';

type StorefrontsResponse = {
  id: string;
  displayName: string;
}[];

export const StorefrontsPage = () => {
  const storefronts = useLoaderData() as StorefrontsResponse;
  return (
    <RouteGuard>
      <>
        <div className="pt-2 flex flex-wrap justify-between gap-8 items-center mb-2">
          <Breadcrumbs path={[{ title: 'Storefronts', to: '#' }]} />
          <Button color="special">
            <Link to="./add">+ Add</Link>
          </Button>
        </div>
        <ul>
          {storefronts.map((el, index) => (
            <li key={index}>
              <FlatPanel>
                <FlatPanel.Header> {el.displayName} </FlatPanel.Header>
                <div className={clsx(['grid', 'grid-cols-3', 'gap-4'])}>
                  <FlatPanel.Link
                    to={`./${el.id}/email-notifications`}
                    tooltipText="Email Settings"
                  >
                    <span> {envelopeIcon} </span>{' '}
                  </FlatPanel.Link>{' '}
                  <FlatPanel.Link to={`./${el.id}/`} tooltipText="Edit">
                    <span> {gearIcon} </span>{' '}
                  </FlatPanel.Link>
                  <FlatPanel.Link
                    to={`./${el.id}/personas`}
                    tooltipText="View Persona"
                  >
                    <span> {userIcon} </span>{' '}
                  </FlatPanel.Link>
                </div>
              </FlatPanel>
            </li>
          ))}
        </ul>
      </>
    </RouteGuard>
  );
};

export const loader = async () => {
  const toastId = toast.loading('Loading...');
  try {
    const resp = await axios.get<StorefrontsResponse>(
      '/fedirect-mgmt/storefronts'
    );
    toast.dismiss(toastId);
    return resp.data;
  } catch (err) {
    toast.dismiss(toastId);
    console.error('FEDirect:::Loaders:::getAllStorefronts', { err });
    reportError({ message: getErrorMessage(err) });
  }
};

export default StorefrontsPage;
