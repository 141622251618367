import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

export type BreadcrumbPath = {
  title?: string;
  to: string;
};

type Props = {
  path: BreadcrumbPath[];
};

export const Breadcrumbs = ({ path }: Props) => {
  const navigate = useNavigate();

  const _handleRender = (el: BreadcrumbPath, index: number): ReactNode => {
    if (index != path.length - 1) {
      return (
        <>
          <span
            className="font-semibold font-xs cursor-pointer"
            onClick={() => navigate(el.to)}
          >
            {el.title}
            <span className="text-blue-900"> / </span>
          </span>
        </>
      );
    }
    return (
      <span
        className="font-semibold font-xs cursor-pointer"
        onClick={() => navigate(el.to)}
      >
        {el.title}
      </span>
    );
  };

  return (
    <div data-cy="breadcrumbs" className="mb-4 flex gap-2 flex-wrap">
      {path.map((el, index) => (
        <div key={index} className="crumb">
          {_handleRender(el, index)}
        </div>
      ))}
    </div>
  );
};
