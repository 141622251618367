import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { Tooltip } from '../Tooltip';

type Props = {
  color?: 'blue' | 'red' | 'special';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
  text?: string;
  children?: ReactNode;
  className?: string;
  tooltip?: string;
};

export const Button = ({
  text,
  disabled = false,
  onClick,
  color = 'blue',
  children,
  type,
  className,
  tooltip,
}: Props) => {
  const buttonColorsClasses = () => {
    let colorClasses =
      'bg-secondary hover:opacity-40 text-white focus-visible:ring-blue-500';
    if (color == 'red') {
      colorClasses =
        'bg-red-400 text-white hover:bg-red-200 focus-visible:ring-red-500';
    } else if (color == 'special') {
      colorClasses = clsx([
        'special-background', // Check index.css for configuration
        'special-background-animate',
        'w-32',
        'hover:opacity-70',
      ]);
    }
    if (disabled) {
      colorClasses =
        'bg-gray-100 text-gray-900 hover:bg-gray-200 focus-visible:ring-gray-500';
    }
    const defaultClasses =
      'focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-2 px-4 py-2 text-sm font-medium inline-flex justify-center items-center rounded-md';
    return clsx(colorClasses, className, defaultClasses);
  };

  const displayText = () => {
    return text ? text : children;
  };

  return (
    <Tooltip text={tooltip}>
      <button
        type={type ? type : 'submit'}
        className={buttonColorsClasses()}
        onClick={onClick}
        disabled={disabled}
        data-cy="button"
      >
        {displayText()}
      </button>
    </Tooltip>
  );
};
