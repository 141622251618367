import { clsx } from 'clsx';

export const franklinLogoIcon = (
  <svg
    className={clsx([
      'fill-white',
      'sm:w-1/4',
      'float-right',
    ])}
    x="0px"
    y="0px"
    viewBox="0 0 404 86"
  >
    <g>
      <path d="M87.7,32.3h21.6v5H94v3.8h14.5v4.8H94v7h-6.3V32.3z"></path>
      <path
        d="M112.8,38.4h5.1v2.8h0.1c0.6-2.3,2.9-3,5.3-3c4,0,5.6,1.7,5.6,5.7c0,0.4,0,0.8-0.1,1.3h-4.7c0-1.9-0.4-2.7-2.6-2.7
		c-1.9,0-3.2,0.7-3.2,2.4v8h-5.5V38.4z"
      ></path>
      <path
        d="M130.9,47.9c0-4,2.7-4.2,8-4.2c3,0,4.6,0.1,5.7,1.2v-1.3c0-1.4-0.4-1.9-2.6-1.9h-3.2c-1.6,0-2.1,0.1-2.1,1.2h-5.3
		c0-3.9,1.9-4.6,6.6-4.6h6.4c3.8,0,5.8,1.8,5.8,5.2V53h-5.2v-1.7c-1.5,1.6-2.1,1.9-4.8,1.9h-3.7c-3.3,0-5.5-0.7-5.5-4.1V47.9z
		 M140.6,49.8c3.8,0,4.1-0.6,4.1-1.5c0-1.1-0.3-1.5-3.3-1.5H139c-2.3,0-2.8,0.5-2.8,1.7c0,0.9,0.7,1.3,2.9,1.3H140.6z"
      ></path>
      <path
        d="M153.6,38.4h5.5v2.3h0.1c1-2,2.9-2.6,5.8-2.6c3.4,0,8.1,0.1,8.1,6.4V53h-5.5v-8.3c0-1.4-1.1-2.1-3.5-2.1h-1.3
		c-2.3,0-3.7,1.1-3.7,3.2V53h-5.5V38.4z"
      ></path>
      <path d="M176.9,32.3h5.5v10.9h1l4.8-4.8h7.2l-7.3,6.8l8.2,7.7h-7l-5.8-5.8h-1.1V53h-5.5V32.3z"></path>
      <path d="M199,32.3h5.5V53H199V32.3z"></path>
      <path d="M214.3,53h-5.5V38.4h5.5V53z M214.3,36.6h-5.5v-4.3h5.5V36.6z"></path>
      <path
        d="M218.4,38.4h5.5v2.3h0.1c1-2,2.9-2.6,5.8-2.6c3.4,0,8.1,0.1,8.1,6.4V53h-5.5v-8.3c0-1.4-1.1-2.1-3.5-2.1h-1.3
		c-2.3,0-3.7,1.1-3.7,3.2V53h-5.5V38.4z"
      ></path>
      <path d="M256,32.3h22.6v4.8h-16.2v3.3h15.4v4.3h-15.4V48h16.6v5H256V32.3z"></path>
      <path d="M282.8,32.3h5.5V53h-5.5V32.3z"></path>
      <path
        d="M291.7,44.9c0-5.5,2.7-6.7,6.7-6.7h3.8c5.8,0,8.2,1.2,8.2,6.3v2.4h-13.3v0.9c0,1.5,1.1,2,3.4,2h1.7c3,0,3.1-0.8,3.1-1.7
		h5.1v0.6c0,3.6-3.3,4.4-7.2,4.4h-4.1c-3.6,0-7.4-0.7-7.4-6.9V44.9z M305.2,43.7c0-1.7-0.8-2.1-4-2.1c-3.6,0-4,0.3-4,2.1H305.2z"
      ></path>
      <path
        d="M312.9,44.9c0-5.5,2.7-6.7,6.7-6.7h6.2c4,0,6.5,1.5,6.5,4.8v1.3H327c0-1.5-0.8-2-3.2-2h-2.1c-2.3,0-3.4,0.6-3.4,2.2v1.9
		c0,1.9,1.2,2.4,2.9,2.4h2.8c1.5,0,2.9-0.3,2.9-1.7v-0.3h5.3v1.3c0,3.9-3.2,5-6.4,5h-5.6c-3.6,0-7.4-0.7-7.4-6.9V44.9z"
      ></path>
      <path
        d="M336.7,42.4h-2.7v-3.9h2.7V35h5.5v3.4h8.5v3.9h-8.5v5.6c0,1.1,0.8,1.3,2.1,1.3c1.8,0,2.3-0.4,2.3-1.7v-1h4.8v1.8
		c0,2.8-1.8,4.9-6.2,4.9h-2.2c-3.4,0-6.3-1.1-6.3-5.1V42.4z"
      ></path>
      <path
        d="M354.4,38.4h5.1v2.8h0.1c0.6-2.3,2.9-3,5.3-3c4,0,5.6,1.7,5.6,5.7c0,0.4,0,0.8-0.1,1.3h-4.7c0-1.9-0.4-2.7-2.6-2.7
		c-1.9,0-3.2,0.7-3.2,2.4v8h-5.5V38.4z"
      ></path>
      <path d="M379.2,53h-5.5V38.4h5.5V53z M379.2,36.6h-5.5v-4.3h5.5V36.6z"></path>
      <path
        d="M382.6,44.9c0-5.5,2.7-6.7,6.7-6.7h6.2c4,0,6.5,1.5,6.5,4.8v1.3h-5.3c0-1.5-0.8-2-3.2-2h-2.1c-2.3,0-3.4,0.6-3.4,2.2v1.9
		c0,1.9,1.2,2.4,2.9,2.4h2.8c1.5,0,2.9-0.3,2.9-1.7v-0.3h5.3v1.3c0,3.9-3.2,5-6.4,5H390c-3.6,0-7.4-0.7-7.4-6.9V44.9z"
      ></path>
      <path
        d="M74.9,38.3c1.1,1.2,1.2,2.2,1.2,3.6H59.4c-0.6-6.5-3.7-12.3-9.4-16.2c-4.7-3.4-11.5-3.8-17.1-2.4
		c-7.3,2.5-13.7,9.7-14.3,17.4l-0.1,1.2H2c0.1-1.7,0-2.5,1.1-3.6c0,0,25.1-25.5,35.9-36.3L74.9,38.3z"
      ></path>
      <polygon
        points="28.1,31.4 28.1,60.4 33.3,56.9 33.3,44.5 37.8,41 37.8,34.4 33.3,37.9 33.3,34.9 38.8,30.2 38.8,50 49.8,60.5
		49.9,53.4 45,49 45,45.5 49.9,49.9 49.9,43.8 45,39.5 45,36.1 49.9,40.3 49.9,32.9 40.1,23.2 38,23.2 	"
      ></polygon>
      <path
        d="M74.9,47.7c1.2-1.2,1.2-2.2,1.2-3.6l-16.7,0c-0.6,6.5-3.7,12.3-9.4,16.2c-4.7,3.4-11.5,3.8-17.1,2.4
		c-7.3-2.5-13.7-9.7-14.3-17.4l-0.1-1.2H2c0,2.2,0,2.6,1.2,3.9C14.8,59.6,28.3,73.2,39,84L74.9,47.7z"
      ></path>
    </g>
  </svg>
);
