import { Dispatch, SetStateAction } from 'react';
import { clsx } from 'clsx';
import { Link } from 'react-router-dom';
import { franklinLogoIcon, burgerMenuIcon } from '@/assets';

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const HeaderBar = ({ setIsOpen }: Props) => {
  return (
    <div
      className={clsx([
        'border-b',
        'border-white',
        'bg-primary',
        'text-white',
        'p-2',
        'w-full',
        'items-center',
        'grid',
        'grid-cols-2',
        'special-background',
      ])}
    >
      <i>
        <button onClick={() => setIsOpen((prev) => !prev)}>
          {burgerMenuIcon}
        </button>
      </i>
      <i>
        <Link to="/">{franklinLogoIcon}</Link>
      </i>
    </div>
  );
};
