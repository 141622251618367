import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Buttons';

type Props = {
  to?: string;
  children: ReactNode;
  disabled?: boolean;
  tooltipText?: string;
};

const navigationLinkGenerator = (disabled: boolean, to?: string) => {
  if (to == null || disabled) {
    return '#';
  }
  return to;
};
export const PanelLink = ({
  children,
  to,
  tooltipText,
  disabled = false,
}: Props) => {
  const navigationLink = navigationLinkGenerator(disabled, to);
  return navigationLink == '#' ? (
    <>
      <NavButton disabled={disabled} tooltipText={tooltipText}>
        {children}
      </NavButton>
    </>
  ) : (
    <>
      <Link to={navigationLink}>
        <NavButton disabled={disabled} tooltipText={tooltipText}>
          {children}
        </NavButton>
      </Link>
    </>
  );
};

type NavButtonProps = {
  disabled: boolean;
  tooltipText?: string;
  children: ReactNode;
};
/**
 *
 * @param disabled `boolean`
 * @param tooltipText `string`
 * @param children `ReactNode`
 * @default disabled: false
 * @returns `ReactNode`
 */
const NavButton = ({
  disabled = false,
  tooltipText,
  children,
}: NavButtonProps) => {
  return (
    <Button disabled={disabled} tooltip={tooltipText}>
      {children}
    </Button>
  );
};
